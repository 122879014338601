<template>
  <div>
    <router-link :to="{ name: 'admin.testimonials.create' }" class="btn btn-primary btn-sm float-end">Add New</router-link>
    <h3>Testimonials</h3>
    <div class="card" v-if="! loading">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Service</th>
              <th>Rating</th>
              <th>Content</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(testimonial, i) in testimonials" :key="`testimonial-${i}`">
              <td>{{ i }}</td>
              <td>{{ testimonial.name }}</td>
              <td>{{ testimonial.service }}</td>
              <td>{{ testimonial.rating }}</td>
              <td>{{ testimonial.content }}</td>
              <td>{{ testimonial.status }}</td>
              <td>
                <a href="#" @click.prevent="update(testimonial.id)">{{ testimonial.status == 'pending' ? 'activate' : 'hide' }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      testimonials: [],
      loading: true
    }
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/admin/testimonials').then(response => {
        this.testimonials = response.data.testimonials
        this.loading = false
      })
    },

    update(id) {
      this.$axios.put(`/api/v1/admin/testimonials/${id}`).then(() => {
        this.fetch()
      })
    }
  }
}
</script>